import '/css/main.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';

import * as bootstrap from 'bootstrap';
import $ from 'jquery';

import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://2a83272742414f1bb70a89b9f75e79ba@o4504990620909568.ingest.sentry.io/4504990623727616",
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Sentry.Replay({maskAllText: false, maskAllInputs: false, blockAllMedia: false}), new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
});

import { renderLibrary } from '/js/library.js';
import { authStatus, promptSignIn, userData, updateUserData } from '/js/accounts.js';
import { loader, showLoader, hideLoader } from '/js/navigation.js';
import { svInit, APP_VERSION } from '@shared/js/instance.js';

svInit();

export var libraryRendered = false;

// Loader
showLoader();

// Authentication check called right away and recursively
setTimeout(persistentAuthCheckCallback, 0); // Call the function immediately

function persistentAuthCheckCallback() {
  authStatus(authCheckCallback);
  setTimeout(persistentAuthCheckCallback, 5000); // 5000 milliseconds = 5 seconds
}

function authCheckCallback(isLoggedIn) {
  if (isLoggedIn && !libraryRendered) {
	updateUserData()
	  .then(() => {
		hideLoader();
		if (!$(".vault-container").length) {
			renderLibrary();
		}
		libraryRendered = true;
	  })
	  .catch((error) => {
		console.log("Error fetching account info");
	  });

	if ($("#authPrompt").length == 1) {
	  // destroySignInPrompt();
	}
  } else if (!isLoggedIn) {
	if ($("#authPrompt").length == 0) {
	  promptSignIn();
	}
	libraryRendered = false;
  }
}