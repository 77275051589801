import * as bootstrap from 'bootstrap';
import $ from 'jquery';
import moment from 'moment';

import { v1BaseUrl } from '@shared/js/apiV1.js';
import { navBarHtml, loader, showLoader, hideLoader } from '/js/navigation.js';
import { renderPlayer } from '/js/stream.js';

import { userData, resendEmailConfirmation } from '/js/accounts.js';

export function renderLibrary(alertClass = null, alertMessage = null) {
  let app = $("#app");

  app.empty();
  app.append(navBarHtml("My Vault"));

  let container = $('<div class="container pt-4 pb-5 vault-container"></div>');
  
  if (!userData.emailVerified) {
	  alertClass = "danger";
	  alertMessage = `Your email address (${userData.email}) needs to be verified before you can stream or redeem shows. Please check your email for the link we sent you in order to verify your email address. Need a new link? <a id="resendEmailConfirmationBtn" class="btn btn-link p-0 pb-1">Resend Email</a>.`;
  }

  let tiles = $(
	'<div class="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6 g-3 mt-auto"></div>'
  );
  
  let alertBox = null;
	if (alertMessage) {
	  alertBox = $(`<div class="alert alert-${alertClass} mt-3 alert-dismissible fade show" role="alert">${alertMessage}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>`);
	  container.append(alertBox);
	}
  // Add skeleton loaders
	for (let i = 0; i < 10; i++) {
	  let skeletonCard = $('<div class="card rounded-3 bg-danger-subtle" aria-hidden="true"></div>');
	  let skeletonImg = $('<div class="card-img-top placeholder-glow" style="background-color: rgba(255, 255, 255, 0.1); padding-bottom: 150%;"></div>');
	  let skeletonCardBody = $('<div class="card-body"></div>');
	  let skeletonCardTitle = $('<h5 class="card-title placeholder-glow"><span class="placeholder" style="width:' + (50 + Math.random() * 50) + '%"></span></h5>');
	  
	  let widths = [
		40 + Math.random() * 60,
		20 + Math.random() * 30,
		20 + Math.random() * 30,
		30 + Math.random() * 50,
		50 + Math.random() * 50
	  ];
	  
	  let sumWidth = widths.reduce((a, b) => a + b, 0);
	  if (sumWidth > 100) {
		widths[widths.length - 1] -= sumWidth - 100;
	  }
	  
	  let skeletonCardText = $(`
		<p class="card-text placeholder-glow">
		  <span class="placeholder" style="width:` + widths[0] + `%"></span>
		  <span class="placeholder" style="width:` + widths[1] + `%"></span>
		  <span class="placeholder" style="width:` + widths[2] + `%"></span>
		  <span class="placeholder" style="width:` + widths[3] + `%"></span>
		  <span class="placeholder" style="width:` + widths[4] + `%"></span>
		</p>
	  `);
	  
	  skeletonCardBody.append(skeletonCardTitle, skeletonCardText);
	  skeletonCard.append(skeletonImg, skeletonCardBody);
	  tiles.append($('<div class="col"></div>').append(skeletonCard));
	}

  container.append(tiles);
  app.append(container);
  
  if (!userData.emailVerified) {
		$("#resendEmailConfirmationBtn").on('click', function(event) {
			resendEmailConfirmation();
		});
	}

  fetchShows().then((shows) => {
	// Empty the tiles container to remove skeleton loaders
	tiles.empty();
	
	if (shows.length === 0) {
	  // Display a message if no titles are available
	  const noTitlesMessage = $('<div class="alert alert-info mt-3">Your vault is empty. To add shows to your Stage Vault, click on the "Redeem" button and enter a valid show code.</div>');
	  container.append(noTitlesMessage);
	} else {

	shows.forEach((show) => {
	  // Data
	  let _id = show.id;
	  let _coverArt = show.coverArt;
	  let _title = show.title;
	  let _subtitle = show.subtitle;
	  let _category = show.category;
	  let _season = show.season;
	  let _year = show.year;
	  let _release = moment(show.releaseAt).format("MMMM Do");

	  // Rendering
	  let col = $(`<div class="col" id="show_${_id}"></div>`);
	  let card = $(
		'<div class="card rounded-3 overflow-hidden h-100 bg-danger-subtle" style="cursor: pointer;"></div>'
	  );
	  let image = $(`<div class="card-img-top" style="background-image: url('${_coverArt}'); background-size: cover; background-position: center; padding-bottom: 150%;"></div>`);
	  let releaseNote = $(`<div class="bg-black p-2 mb-2 fs-6 text-center"><span class="text-warning fw-bold">Available ${_release}</span></div>`);
	  let body = $('<div class="card-body d-flex flex-column justify-content-between"></div>');
	  let top = $('<div></div>');
	  	let title = $(`<span class="d-block fs-4 lh-sm fw-semibold text-light">${_title}</span>`);
	  	let subtitle = $(`<span class="d-block fs-5 text-light">${_subtitle}</span>`);
	  let bottom = $('<div></div>');
	  	let description = $(`<span class="d-block mt-2 mb-1 fs-6">${_category} &middot; ${_season} ${_year}</span>`);
	  top.append(title, subtitle);
	  bottom.append(description);
	  body.append(top, bottom);
	  if (moment(show.releaseAt).unix() > moment().unix()) {
		  	card.addClass('grayscale-custom');
			card.append(image, releaseNote, body);
		} else {
			card.removeClass('grayscale-custom');
			card.append(image, body);
		}
	  col.append(card);
	  tiles.append(col);
	  
	  // Selectors
	  card.on('click', function() {
		  if (!userData.emailVerified) {
			alert("You must verify your email before watching a show!");
			return;
		  }
		  if (moment(show.releaseAt).unix() > moment().unix()) {
			alert(`This show has not been released yet. Please try again after ${moment(show.releaseAt).format("dddd, MMMM Do YYYY, h:mm:ss a")}`);
			return;
		  }
		  renderPlayer(_id);
	  });
	});
	
		container.append(tiles);
	}
  }).catch((error) => {
	  console.error('Error fetching shows:', error);
	});
}

function fetchShows() {
  return new Promise((resolve, reject) => {
	$.ajax({
	  url: v1BaseUrl + "account/shows",
	  type: "GET",
	  xhrFields: {
		withCredentials: true
	  },
	  dataType: "json",
	  success: function (data) {
		if (data.shows) {
		  resolve(data.shows);
		} else {
		  reject(new Error("No shows found in the response"));
		}
	  },
	  error: function (jqXHR, textStatus, errorThrown) {
		// Handle errors here
		console.error("Error fetching shows: " + textStatus, errorThrown);
		reject(new Error("Error fetching shows: " + textStatus));
	  },
	});
  });
}


// Simulated API fetch
// function fetchShows() {
//   return new Promise((resolve) => {
// 	const shows = [
// 	  {
// 		id: "481539dd-f56b-48e7-bd06-2583ef9a3b49",
// 		coverArt: "https://s3.amazonaws.com/prdv2-dt-client/uploads/uqwhAK3jFcQ05cMKuJ0KRckC320wVAiR.jpg",
// 		title: "Mamma Mia",
// 		subtitle: "Cast A",
// 		category: "Musical",
// 		season: "Fall",
// 		year: 2021,
// 	  },
// 	  {
// 		  id: "481539dd-f56b-48e7-bd06-2583ef9a3b50",
// 		  coverArt: "https://s3.amazonaws.com/prdv2-dt-client/uploads/uqwhAK3jFcQ05cMKuJ0KRckC320wVAiR.jpg",
// 		  title: "Mamma Mia",
// 		  subtitle: "Cast B",
// 		  category: "Musical",
// 		  season: "Fall",
// 		  year: 2021,
// 		}
// 	];
// 	resolve(shows);
//   });
// }