import $ from 'jquery';
import * as bootstrap from 'bootstrap';

import { renderLibrary } from '/js/library.js';
import { v1BaseUrl } from '@shared/js/apiV1.js';
import { watchNavBarHtml, loader, showLoader, hideLoader } from '/js/navigation.js';

let cfsCustomerId = "customer-mpcxr1ym2mpbo7ew";

// Debounce function to handle rapid calls more efficiently
function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

// RESET
let currentUpdateInterval = null;
let currentPlayer = null;

export async function renderPlayer(showId) {
  const app = $("#app");
  app.empty();
  
  showLoader();
  
  // Clear existing intervals and detach old event listeners if any
  if (currentUpdateInterval) {
	  clearInterval(currentUpdateInterval);
	  currentUpdateInterval = null;
  }
  if (currentPlayer) {
	  // Remove all event listeners attached to the old player
	  ['play', 'pause', 'ended', 'seeked', 'timeupdate'].forEach(event => {
		  currentPlayer.removeEventListener(event);
	  });
	  currentPlayer = null;
  }

  try {
	// Load SDK first
	await loadStreamSDK();  
	
	// Data
	let show = await fetchShow(showId);
	let showTitle = show.title;
	let streamingToken = await authorizeStream(showId);
	let progress = await fetchProgress(showId); // Fetch progress

	// Initialize variables
	const nav = watchNavBarHtml(showTitle).addClass("player-nav");
	
	const iframeWrap = $('<div class="container-fluid h-100 p-0 bg-black player-iframe-wrap"></div>').addClass("player-iframe-wrap");

	const iframe = $(`
	  <iframe
	    id="streamPlayer"
		src="https://${cfsCustomerId}.cloudflarestream.com/${streamingToken}/iframe?autoplay=false&startTime=${progress.position}"
		style="background-color: black; border: none; height: 100%; width: 100%;"
		allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
		allowfullscreen="true"
	  >
	`);
	
	iframe.addClass("d-none");
	
	iframe.on('load', function() {
		iframe.removeClass("d-none");
		const player = window.Stream(document.getElementById('streamPlayer'));
		currentPlayer = player;  // Update the current player reference
	
		const debouncedUpdateProgress = debounce(() => {
			updateProgress(player, showId);
		}, 1000, true);
	
		player.addEventListener('play', () => {
			currentUpdateInterval = setInterval(() => {
				if (!player.paused && !player.ended) {
					updateProgress(player, showId);
				}
			}, 5000); // 5 seconds
		});
	
		player.addEventListener('pause', () => {
			clearInterval(currentUpdateInterval);
			debouncedUpdateProgress();
		});
	
		player.addEventListener('ended', () => {
			clearInterval(currentUpdateInterval);
			debouncedUpdateProgress();
		});
	
		player.addEventListener('seeked', debouncedUpdateProgress);
		player.addEventListener('timeupdate', debouncedUpdateProgress);
	});


	const playerContainer = $('<div class="player-container"></div>');
	playerContainer.append(nav, iframeWrap);

	// Append the elements to the DOM
	iframeWrap.append(iframe);
	app.append(playerContainer);
	hideLoader();

	// Check for iOS device and PWA
	function isIOS() {
	  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
	}

	function isPWAInstalled() {
	  return window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
	}

	if (isIOS() && isPWAInstalled()) {
	  iframeWrap.addClass('pb-5');
	}
  } catch (error) {
	console.error("Error rendering player:", error);
	renderLibrary("danger", error);
  }
}

function fetchProgress(showId) {
  return new Promise((resolve, reject) => {
	$.ajax({
	  url: `${v1BaseUrl}show/progress`,
	  type: "POST",
	  data: { show: showId },
	  xhrFields: {
		withCredentials: true
	  },
	  success: function(data) {
		resolve(data);
	  },
	  error: function(jqXHR, textStatus, errorThrown) {
		console.error("Error fetching progress:", textStatus, errorThrown);
		reject(new Error("Unable to fetch progress"));
	  }
	});
  });
}

function fetchShow(id) {
  return new Promise((resolve, reject) => {
	$.ajax({
	  url: v1BaseUrl + "show/info",
	  type: "POST",
	  xhrFields: {
		withCredentials: true
	  },
	  data: {
	  	show: id
	  },
	  dataType: "json",
	  success: function (data) {
		if (data) {
		  resolve(data);
		} else {
		  reject(new Error("No show found in the response"));
		}
	  },
	  error: function (jqXHR, textStatus, errorThrown) {
		// Handle errors here
		console.error("Error fetching show: " + textStatus, errorThrown);
		reject(new Error("Unable to fetch show"));
	  },
	});
  });
}

function authorizeStream(id) {
	return new Promise((resolve, reject) => {
		$.ajax({
		  url: v1BaseUrl + "show/stream",
		  type: "POST",
		  xhrFields: {
			withCredentials: true
		  },
		  data: {
				show: id
			},
		  dataType: "json",
		  success: function (data) {
			if (data.authorized) {
				if (data.token == "NO_RESULT") {
					reject(new Error("404 · Video currently unavailable. Please try again later."));
				} else {
					resolve(data.token);
				}
			} else {
			  reject(new Error("403 · You are not authorized to view this content."));
			}
		  },
		  error: function (jqXHR, textStatus, errorThrown) {
			// Handle errors here
			console.error("Error authorizing stream: " + textStatus, errorThrown);
			reject(new Error("An unknown error occured while authorizing playback. Please try again later."));
		  },
		});
	  });
}

async function loadStreamSDK() {
  if (!window.Stream) {
	await new Promise((resolve, reject) => {
	  const script = document.createElement('script');
	  script.src = 'https://embed.cloudflarestream.com/embed/sdk.latest.js';
	  script.onload = () => resolve();
	  script.onerror = () => reject(new Error("Failed to load Cloudflare Stream SDK"));
	  document.head.appendChild(script);
	});
  }
}

function updateProgress(player, showId) {
	const position = player.currentTime; // Assuming this is how you get the current time from the player

	$.ajax({
		url: `${v1BaseUrl}show/progress`,
		type: "POST",
		data: {
			show: showId,
			position: position
		},
		xhrFields: {
			withCredentials: true
		},
		success: function(data) {
			console.log("Progress updated successfully:", data);
		},
		error: function(jqXHR, textStatus, errorThrown) {
			console.error("Error updating progress:", textStatus, errorThrown);
		}
	});
}
