import * as bootstrap from '../../cms/node_modules/bootstrap';

export function mkToast(id, bsc, title, body, timeout) {
  // Check if a toast wrapper already exists
  var toastWrapper = document.querySelector('.toast-container');
  
  // If not, create one and append to the body
  if(!toastWrapper) {
	toastWrapper = document.createElement('div');
	toastWrapper.classList.add('toast-container', 'position-static');
	document.body.appendChild(toastWrapper);
  }
  
  // Create a new toast inside the wrapper
  var toast = document.createElement('div');
  toast.id = id + '-toast';
  toast.classList.add('toast', 'bg-' + bsc, 'text-white');
  toast.style.position = 'fixed';
  toast.style.bottom = '1em';
  toast.style.right = '1em';
  toast.style.zIndex = 9999;
  toast.role = 'alert';
  toast.ariaLive = 'assertive';
  toast.ariaAtomic = true;
  
  var toastHTML = `
	<div class="toast-header">
	  <strong class="me-auto">` + title + `</strong>
	  <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
	</div>
	<div id="` + id + `-toast-body" class="toast-body">` + body + `</div>
  `;
  
  toast.innerHTML = toastHTML;
  
  // Append the toast to the toast wrapper
  toastWrapper.appendChild(toast);
  
  // Initialize the Bootstrap Toast
  var errorToast = new bootstrap.Toast(toast, {autohide: true, delay: timeout});
  errorToast.show();
  
  // Remove the toast from the DOM after it has been hidden
  setTimeout(() => {
	errorToast.hide();
	toastWrapper.removeChild(toast);
	
	// Remove the wrapper if there are no more toasts
	if(toastWrapper.childNodes.length === 0) {
	  document.body.removeChild(toastWrapper);
	}
  }, timeout);
}
