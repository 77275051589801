// Returns the API base url as "http/https://example.com/v1/" in a contextual manner.
function apiV1Url() {
	// Development
	if (window.location.href.includes("http://localhost:5173")) {
		return "http://api.stagevault.com:8001/v1/";
	}
	
	if (window.location.href.includes("admin.stagevault.com")) {
		return "https://api.stagevault.com/v1/";
	}
	
	if (window.location.href.includes("stagevault.dev-proxy.net")) {
		return "https://stagevault-api.dev-proxy.net/v1/";
	}
	
	if (window.location.href.includes("stagevault-admin.dev-proxy.net")) {
		return "https://stagevault-api.dev-proxy.net/v1/";
	}
	
	// Get the current URL and split it into its component parts
	var currentUrl = window.location.href;
	var urlParts = currentUrl.split("/");
	
	// Extract the subdomain from the URL
	var subdomain = urlParts[2].split(".")[0];
	
	// Construct the URL for the authorization status API endpoint
	var apiUrl = urlParts[0] + "//api." + urlParts[2] + "/v1/";

	return apiUrl;
}

export function imageUrl(guid) {
	return v1BaseUrl + "content/image?id=" + guid;
}

export const v1BaseUrl = apiV1Url();