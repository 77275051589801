import * as bootstrap from 'bootstrap';
import $ from 'jquery';

import { v1BaseUrl } from '@shared/js/apiV1.js';
import { renderLibrary } from '/js/library.js';

function redeem() {
	// UI
	$('#reAlert').addClass('d-none');
	
	$("#rmCancelBtn").addClass('disabled');
	$('#codeInput').attr('disabled', true);
	$("#redeemBtn").addClass('d-none');
	$("#redeemBtnLoading").removeClass('d-none');
	
	let code = $("#codeInput").val();
	
	let reqData = {
		"code": code
	};
	
	$.ajax({
		url: v1BaseUrl + "account/redeem",
		type: "post",
		xhrFields: {
			withCredentials: true
		  },
		dataType: 'json',
		data: reqData,
		success: function (data) {
	
			// UI
			$("#rmCancelBtn").removeClass('disabled');
			$('#codeInput').attr('disabled', false);
			$("#redeemBtn").removeClass('d-none');
			$("#redeemBtnLoading").addClass('d-none');
	
			if (data.success) {
				let showTitle = data.show.title;
				$("#codeInput").val("");
				dismissRedemptionModal();
				renderLibrary('success', `Successfully redeemed "${showTitle}". It has been added to your vault.`);
			} else {
				$('#reAlert').removeClass('d-none');
				$('#reAlert').text('Oops! You\'ve entered an invalid code, please try again.');
				$('#codeInput').val("");
				if (data.error !== null) {
					$('#reAlert').text(data.error);
				}
			}
	
		},
		error: function () {
	
			// UI
			$("#rmCancelBtn").removeClass('disabled');
			$('#codeInput').attr('disabled', false);
			$("#redeemBtn").removeClass('d-none');
			$("#redeemBtnLoading").addClass('d-none');
	
			$('#reAlert').removeClass('d-none');
			$('#reAlert').text('Connection Failed! Please make sure you\'re connected to the internet.');
	
		}
	});

}

export function renderRedemptionModal() {
  // Create elements
  const modal = $('<div class="modal fade show" tabindex="-1" id="redemptionModal" aria-modal="true" role="dialog"></div>');
  const modalDialog = $('<div class="modal-dialog"></div>');
  const modalContent = $('<div class="modal-content"></div>');
  const modalHeader = $('<div class="modal-header"></div>');
  const modalTitle = $('<h5 class="modal-title text-white">Redeem Show</h5>');
  const closeButton = $('<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>');
  const modalBody = $('<div class="modal-body"></div>');
  const alert = $('<div class="alert alert-danger d-none" role="alert" id="reAlert">An unknown error has occurred.</div>');
  const modalDescription = $('<p>Enter your show code to add the media to your account. Please note that once a code is redeemed, it cannot be used again or transferred to another account.</p>');
  const codeInput = $('<input class="form-control form-control-lg w-100 font-monospace" type="text" placeholder="ABCD1234" name="redemption_code" id="codeInput">');
  const modalFooter = $('<div class="modal-footer"></div>');
  const redeemButton = $('<button type="button" class="btn btn-primary" id="redeemBtn">Redeem</button>');
  const redeemButtonLoading = $('<button class="btn btn-primary d-none" type="button" id="redeemBtnLoading" disabled=""><span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Redeeming...</button>');

  // Assemble the modal
  modalHeader.append(modalTitle, closeButton);
  modalBody.append(alert, modalDescription, codeInput);
  modalFooter.append(redeemButton, redeemButtonLoading);
  modalContent.append(modalHeader, modalBody, modalFooter);
  modalDialog.append(modalContent);
  modal.append(modalDialog);
  
  // Add the click event listener for the redeem button
  redeemButton.on('click', function () {
	redeem();
  });
  
  // Update the click event listener for the close button
  closeButton.on('click', function () {
	dismissRedemptionModal();
  });

  // Append the modal to the body
  $('body').append(modal);
}

export function dismissRedemptionModal() {
  const modalElement = document.getElementById('redemptionModal');
	const modalInstance = bootstrap.Modal.getInstance(modalElement);
	if (modalInstance) {
	  modalInstance.hide();
	}
}